<template>
  <div>
    <!-- main banner -->
    <div
      class="bg-no-repeat bg-cover lg:bg-center"
      style="background-image: url('/images/main-banner.jpg')"
    >
      <div
        class="container py-20 lg:flex lg:justify-center lg:items-start xl:items-center"
      >
        <img
          src="/images/banner-product.png"
          alt=""
          class="w-full md:w-2/3 md:mx-auto lg:w-1/2 lg:order-2"
        />
        <!-- tagline -->
        <div class="hidden lg:block lg:w-1/2">
          <img
            src="/images/logo-white.png"
            alt=""
            class="w-32 pb-4 mx-auto md:w-40 md:mx-0"
          />
          <div class="text-white">
            <div class="bg-white w-10 h-0.5"></div>
            <h1 class="pt-4 text-2xl font-medium">LOOKING FOR</h1>
            <h1 class="pt-2 text-5xl font-bold">
              <span class="text-hy-liteblue">LCD & DLP</span> PROJECTORS AND
              SCREEN?
            </h1>
          </div>

          <p class="mt-4 text-xl font-semibold text-white">
            WIDE AND COMPLETE RANGE OF YOUR CHOICE
          </p>
          <p class="py-3 text-lg font-medium text-white">
            Contact Us Today For
          </p>
          <!-- cta button -->
          <div class="pt-4 md:pt-8 lg:pt-0">
            <h1
              class="py-2 mx-auto font-semibold text-center text-white transition duration-300 ease-in-out md:mx-0 hover:bg-blue-500 w-52 bg-hy-liteblue rounded-xl"
            >
              <a href="https://wa.me/60123117001?text=Hi,%20I'm%20looking%20for%20projector%20and%20projector%20screen,%20may%20I%20have%20more%20info?%20Thank%20you.">Special Deals</a>
            </h1>
          </div>
        </div>
      </div>
    </div>

    <!-- mobile-tagline  -->
    <div class="pt-4 pb-6 bg-white lg:hidden">
      <div class="container">
        <img
          src="/images/logo.png"
          alt=""
          class="w-32 pb-4 mx-auto md:w-40 md:mx-0"
        />
        <div class="text-gray-700">
          <div class="bg-gray-500 w-10 h-0.5"></div>
          <h1 class="pt-4 text-lg font-medium text-hy-darkblue">LOOKING FOR</h1>
          <h1 class="pt-2 text-4xl font-bold text-hy-darkblue">
            <span class="text-hy-liteblue">LCD & DLP</span> PROJECTORS AND
            SCREEN?
          </h1>

          <p class="mt-4 text-lg font-semibold leading-tight text-hy-darkblue">
            WIDE AND COMPLETE RANGE OF YOUR CHOICE
          </p>
          <p class="py-3 text-lg font-medium text-hy-darkblue">
            Contact Us Today For
          </p>
        </div>
        <div class="pt-0">
          <h1
            class="py-2 mx-0 font-semibold text-center text-white transition duration-300 ease-in-out md:mx-0 hover:bg-blue-500 w-52 bg-hy-liteblue rounded-xl"
          >
            <a href="https://wa.me/60123117001?text=Hi,%20I'm%20looking%20for%20projector%20and projector%20screen,%20may%20I%20have%20more%20info?%20Thank%20you.">Special Deals</a>
          </h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hideEnquiry: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
