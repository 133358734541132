<template>
  <div>
    <WaBtn />
    <MainBanner />

    <!-- LCD Projector Brands -->
    <div class="py-10">
      <div class="container">
        <h1 class="text-2xl font-semibold text-center text-gray-700">
          Featured Brand
        </h1>
        <div class="bg-lite-blue w-10 h-0.5 mx-auto my-3"></div>

        <div class="pt-5 md:pt-8 md:flex md:flex-wrap xl:max-w-4xl xl:mx-auto">
          <div
            v-for="image in brands"
            :key="image"
            class="w-2/3 pb-2 mx-auto md:w-1/4 md:px-1"
          >
            <img :src="image" alt="" class="mx-auto w-52" />
          </div>
        </div>
      </div>
    </div>

    <!-- our services -->
    <div class="py-10 bg-gray-50">
      <div class="container">
        <h1 class="text-2xl font-semibold text-center text-gray-700">
          Our Services
        </h1>
        <div class="bg-lite-blue w-10 h-0.5 mx-auto my-3"></div>

        <div class="flex flex-wrap mt-8">
          <div
            v-for="(item, i) in services"
            :key="i"
            class="w-1/2 p-1 text-center md:w-1/3"
          >
            <div
              class="p-5 transition duration-200 ease-linear transform bg-white rounded-md hover:scale-105 hover:shadow-lg"
            >
              <img :src="item.image" :alt="item.alt" class="w-20 mx-auto" />
              <div class="mt-3 text-sm text-gray-600 md:h-12">
                <h1 class="leading-tight">{{ item.alt }}</h1>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-6">
          <h1
            class="w-40 py-2 mx-auto font-medium text-center text-white transition duration-200 ease-linear transform rounded-md hover:shadow-lg hover:scale-110 bg-hy-darkblue"
          >
            <a href="https://wa.me/60123117001?text=Hi,%20I'm%20looking%20for%20projector%20and projector%20screen,%20may%20I%20have%20more%20info?%20Thank%20you.">Free Consultation!</a>
          </h1>
        </div>
      </div>
    </div>

    <!-- projector models  -->
    <div
      class="py-10 bg-center bg-no-repeat bg-contain lg:bg-cover"
      style="background-image: url('/images/bg-product.jpg')"
    >
      <div class="container">
        <h1 class="text-2xl font-semibold text-center text-gray-700">
          Featured Products
        </h1>
        <div class="bg-lite-blue w-10 h-0.5 mx-auto my-3"></div>

        <carousel
          :autoplay="true"
          :loop="true"
          :responsive="{
            0: { items: 1, nav: false },
            700: { items: 2, nav: false },
            1000: { items: 3, nav: false },
          }"
        >
          <div v-for="image in projector" :key="image" class="md:px-2">
            <div class="py-6">
              <img :src="image" alt="" class="mx-auto text-center" />
            </div>
          </div>
        </carousel>
      </div>
    </div>

    <!-- Projector Screen Models -->
    <div class="py-10">
      <div class="container">
        <h1 class="text-2xl font-semibold text-center text-gray-700">
          Projection Screen Models
        </h1>
        <div class="bg-lite-blue w-10 h-0.5 mx-auto my-3"></div>
        <carousel
          :autoplay="true"
          :loop="true"
          :responsive="{
            0: { items: 2, nav: false },
            700: { items: 4, nav: false },
            1000: { items: 6, nav: false },
          }"
        >
          <div v-for="(item, i) in screen" :key="i" class="md:px-2 lg:pt-8">
            <div class="pt-6 pb-4">
              <img
                :src="item.image"
                :alt="item.alt"
                class="mx-auto text-center"
              />
            </div>
            <h2
              class="pt-4 text-sm font-medium text-center text-gray-700 md:text-base"
            >
              {{ item.alt }}
            </h2>
          </div>
        </carousel>
      </div>
    </div>

    <!--provide to -->
    <div class="py-10 bg-gray-50">
      <div class="container">
        <h1 class="text-2xl font-semibold text-center text-gray-700">
          Our Services Provide To
        </h1>
        <div class="bg-lite-blue w-10 h-0.5 mx-auto my-3"></div>

        <div class="flex flex-wrap mt-8 md:justify-center">
          <div
            v-for="(item, i) in type_service"
            :key="i"
            class="w-1/2 p-1 md:w-1/3 lg:w-1/5"
          >
            <div
              class="transition duration-200 ease-in-out transform bg-white rounded-md hover:scale-105 hover:shadow-lg"
            >
              <img
                :src="item.image"
                :alt="item.alt"
                class="mx-auto rounded-t-md"
              />
              <div class="py-4 text-sm font-semibold text-center text-gray-600">
                <h1 class="leading-tight">{{ item.alt }}</h1>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-6">
          <h1
            class="w-48 py-2 mx-auto text-center text-white transition duration-200 ease-linear transform rounded-md hover:scale-110 bg-hy-darkblue"
          >
            <a href="https://wa.me/60123117001?text=Hi,%20I'm%20looking%20for%20projector%20and projector%20screen,%20may%20I%20have%20more%20info?%20Thank%20you."
              ><i class="pr-2 fa-brands fa-whatsapp"></i>Contact Us Now!</a
            >
          </h1>
        </div>
      </div>
    </div>

    <!-- banner maxhub -->
    <div class="relative">
      <div
        class="absolute top-0 left-0 w-full h-full bg-top bg-no-repeat bg-cover bgAdjust"
        style="background-image: url('/images/bg-maxhub.jpg')"
      ></div>
      <div class="container relative p-6 md:flex md:items-center customHeight">
        <div class="block md:w-1/2">
          <img
            src="/images/maxhub.png"
            alt=""
            class="w-2/3 mx-auto md:w-4/5 lg:w-2/3 xl:w-1/2"
          />
        </div>
        <div class="w-2/3 mx-auto md:w-1/2">
          <h1 class="pb-6 text-2xl font-bold leading-tight lg:text-3xl">
            MAXHUB Video Conference
          </h1>
          <p
            class="pb-6 text-base font-semibold leading-tight lg:text-xl lg:pb-8"
          >
            No boundary communication,<br />
            No distance collaboration
          </p>
          <p class="pb-4 text-sm lg:text-base lg:pb-8">
            Remote screen sharing <br />
            High quality voice and video connection*<br />
            Multi-party interactive writing<br />
            Professional design ensures information security*
          </p>

          <p class="text-xs text-right">
            Two users can get free access after download and register MAXHUB
            Video Conference, and extra users need to pay for the service.
            *MAXHUB Video Conference adopted end to end ASE256 bit algorithm.
          </p>

          <div class="pt-3 lg:pt-8">
            <h1
              class="p-2 text-center text-white rounded-lg lg:mx-auto lg:w-72 bg-hy-liteblue"
            >
              <a href="tel:+60123117001">Ask For A Quote Now!</a>
            </h1>
          </div>
        </div>
      </div>
    </div>

    <!-- why choose us -->
    <div
      class="py-10 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/bg-choose.jpg')"
    >
      <div class="container">
        <h1 class="text-2xl font-semibold text-center text-white">
          Why Choose Us
        </h1>

        <div class="flex flex-wrap pt-10 lg:justify-center">
          <div
            v-for="(item, i) in choose"
            :key="i"
            class="w-1/2 pb-2 text-center md:w-1/3 lg:w-1/6"
          >
            <img :src="item.image" :alt="item.alt" class="w-16 mx-auto" />
            <h1 class="pt-4 text-sm font-semibold text-white">
              {{ item.alt }}
            </h1>
          </div>
        </div>
        <div class="mt-6">
          <h1
            class="w-48 py-2 mx-auto text-center text-white transition duration-200 ease-linear transform rounded-md hover:scale-110 bg-hy-darkblue"
          >
            <a href="https://wa.me/60123117001?text=Hi,%20I'm%20looking%20for%20projector%20and projector%20screen,%20may%20I%20have%20more%20info?%20Thank%20you."
              ><i class="pr-2 fa-brands fa-whatsapp"></i>Contact Us Now!</a
            >
          </h1>
        </div>
      </div>
    </div>

    <!-- Our Project -->
    <div class="py-10">
      <div class="container">
        <h1 class="text-2xl font-semibold text-center text-gray-700">
          Our Projects
        </h1>
        <div class="bg-lite-blue w-10 h-0.5 mx-auto my-3"></div>

        <div class="mt-8 md:flex md:flex-wrap lg:justify-center">
          <div
            v-for="image in gallery"
            :key="image"
            class="pb-1 md:p-1 md:w-1/2 lg:w-1/3"
          >
            <img :src="image" alt="" />
          </div>
        </div>
      </div>
    </div>

    <!-- About Us -->
    <div class="bg-gradient-to-r from-hy-darkblue to-hy-blue">
      <div class="container py-10 mx-auto">
        <div class="lg:flex lg:items-center">
          <div class="text-white lg:w-1/2 lg:pr-4">
            <div class="pb-6 text-center lg:text-left">
              <h1 class="text-2xl font-semibold text-white">Who We Are</h1>
              <p class="pb-3 text-lg font-semibold leading-tight text-white">
                We Are Dedicated Projector Specialists
              </p>
            </div>
            <p class="text-center lg:text-left">
              Hytech Office Automation (M) Sdn Bhd with 21 years of experience
              in Digital Projectors, Interactive Projectors and other related
              products in Audio Visual Equipment. We offer a comprehensive range
              of Best Projectors and Best Projector screens as well as related
              services.
              <br /><br />
              We use our experience to find you the right projector for your
              specific environment as like Business Office Projectors / Home
              Theatres Projectors / Interactive Projectors and more! In the view
              of our front line, we have an expertise sales team on products
              guidance with their professional skills to meet the various
              aspects of customer needs. They are also the company’s heart. On
              the other hand, there are a group of well-trained technicians for
              software support, training, machine repair & QC in order to ensure
              product quality before the delivery.
            </p>
          </div>
          <div class="pt-5 lg:w-1/2 lg:pt-0">
            <img src="/images/about-us.jpg" alt="About Us" class="mx-auto" />
          </div>
        </div>
      </div>
    </div>

    <!-- Enquiry -->
    <div
      class="py-10 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/enquiry-bg.jpg')"
    >
      <div class="container">
        <div class="p-3 bg-hy-liteblue bg-opacity-70 lg:max-w-3xl lg:mx-auto">
          <h1 class="pt-5 pb-4 text-2xl font-semibold text-center text-white">
            CONTACT US FOR ALL YOUR NEEDS！<br />YOU CAN GET FREE QUOTE NOW
          </h1>
          <p class="pb-4 text-base font-medium text-center text-white">
            Fill out the form below and we'll get back to you as soon as
            possible
          </p>
          <div class="p-4">
            <EnquiryForm />
          </div>
        </div>
      </div>
    </div>

    <!-- sub copier banner -->
    <div
      class="py-10 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/bg-sub-2.jpg')"
    >
      <div class="container">
        <div class="py-10 text-white lg:py-24">
          <h2 class="text-xl font-normal md:text-3xl xl:text-4xl">Looking For</h2>
          <h1 class="text-3xl font-bold md:text-5xl xl:text-6xl">
            Copier <span class="text-hy-liteblue">Machine?</span>
          </h1>
          <div class="pt-4">
            <p
              class="p-2 text-center transition duration-200 transform rounded-md lg:w-60 lg:text-lg bg-hy-liteblue w-52 hover:scale-110"
            >
              <a href="http://www.hytech2u.com/">Click Here For More Info</a>
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Contact -->
    <div class="py-10 bg-white">
      <div class="container">
        <div class="md:flex md:items-center">
          <div class="text-gray-600 md:w-1/2">
            <img src="/images/logo.png" alt="" class="mx-auto w-42" />
            <div class="flex items-center pt-6">
              <i class="pr-2 text-xl text-hy-liteblue fa-solid fa-building"></i>
              <h1 class="text-lg leading-tight">
                Hytech Office Automation (M) Sdn Bhd
              </h1>
            </div>
            <div class="flex items-center pt-5">
              <i
                class="pr-2 text-xl text-hy-liteblue fa-solid fa-location-dot"
              ></i>
              <h1 class="text-base leading-tight">
                No, 4, Jalan Sayang 3, Taman Rasa Sayang, 43200 Cheras, Selangor
              </h1>
            </div>
            <div class="flex items-center pt-5">
              <i class="pr-2 text-xl text-hy-liteblue fa-solid fa-phone"></i>
              <h1 class="text-base leading-tight">
                <a href="tel:+60123117001"> 012-3117001</a> |
                <a href="tel:+60390802000">03-90802000</a>
              </h1>
            </div>
            <div class="flex items-center pt-2">
              <i
                class="pr-2 text-xl text-hy-liteblue fa-brands fa-whatsapp"
              ></i>
              <h1 class="text-base leading-tight">
                <a href="https://wa.me/60123117001?text=Hi,%20I'm%20looking%20for%20projector%20and projector%20screen,%20may%20I%20have%20more%20info?%20Thank%20you."> 012-3117001</a>
              </h1>
            </div>
          </div>
          <div class="pt-5 md:w-1/2 md:pt-0">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3984.0894815780784!2d101.75316500049833!3d3.0707633544744857!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc4a782d0a8b8d%3A0x15e57dd997a86490!2sHytech%20Office%20Automation%20(M)%20Sdn%20Bhd!5e0!3m2!1sen!2smy!4v1607997350676!5m2!1sen!2smy"
              width="100%"
              height="250"
              frameborder="0"
              style="border: 0"
              allowfullscreen=""
              aria-hidden="false"
              tabindex="0"
            ></iframe>
          </div>
        </div>
      </div>
    </div>

    <!-- Copy right -->
    <CopyRight />
  </div>
</template>

<script>
import WaBtn from "@/components/WaBtn.vue";
import carousel from "vue-owl-carousel";
import MainBanner from "@/components/MainBanner.vue";
import CopyRight from "@/components/CopyRight.vue";
import EnquiryForm from "@/components/EnquiryForm.vue";

export default {
  components: {
    MainBanner,
    carousel,
    CopyRight,
    WaBtn,
    EnquiryForm,
  },
  data() {
    return {
      services: [
        {
          image: "/images/service-1.png",
          alt: "Projector Rental / Lease Services",
        },
        {
          image: "/images/service-2.png",
          alt: "Projector and Screen Installation Services",
        },
        {
          image: "/images/service-3.png",
          alt: "Projector Repair Services",
        },
        {
          image: "/images/service-4.png",
          alt: "Projector Switches and Splitters AV solutions",
        },
        {
          image: "/images/service-5.png",
          alt: "Projector Manual, Tripod, Motorized Screens (Front and Rear Screens)",
        },
        {
          image: "/images/service-6.png",
          alt: "Projector Lamp Replacements Services",
        },
      ],
      brands: [
        "/images/brand-1.png",
        "/images/brand-2.png",
        "/images/brand-3.png",
        "/images/brand-4.png",
        "/images/brand-5.png",
        "/images/brand-6.png",
        "/images/brand-7.png",
      ],
      projector: [
        "/images/projector-1.png",
        "/images/projector-2.png",
        "/images/projector-3.png",
      ],
      screen: [
        { image: "/images/screen-1.jpg", alt: "Motorized Projector Lift" },
        { image: "/images/screen-2.jpg", alt: "Projector Mount" },
        { image: "/images/screen-3.jpg", alt: "Manual Screen" },
        { image: "/images/screen-4.jpg", alt: "Tripod Screen " },
        { image: "/images/screen-5.jpg", alt: "Electric Screen" },
        { image: "/images/screen-6.jpg", alt: "Tab Tensioned Screen" },
        { image: "/images/screen-7.jpg", alt: "Fast Fold Screen" },
      ],
      type_service: [
        { image: "/images/provide-1.jpg", alt: "Classroom" },
        { image: "/images/provide-2.jpg", alt: "Meeting Conference Room" },
        { image: "/images/provide-3.jpg", alt: "Cooperate Event" },
        { image: "/images/provide-4.jpg", alt: "Wedding Event" },
        { image: "/images/provide-5.jpg", alt: "Exhibition" },
        { image: "/images/provide-6.jpg", alt: "Home Tutor" },
        { image: "/images/provide-7.jpg", alt: "Tuition Center" },
        { image: "/images/provide-8.jpg", alt: "Mall" },
        { image: "/images/provide-9.jpg", alt: "Restaurant" },
        { image: "/images/provide-10.jpg", alt: "Hotel" },
      ],
      choose: [
        { image: "/images/choose-6.png", alt: "Free Site Consultation" },
        { image: "/images/choose-3.png", alt: "Fast Respond" },
        { image: "/images/choose-1.png", alt: "Direct Factory Price" },
        { image: "/images/choose-5.png", alt: "24/7 Technical Support" },
        { image: "/images/choose-2.png", alt: " Warranty Claim Available" },
        { image: "/images/choose-7.png", alt: "0% Installment Plan" },
      ],
      gallery: [
        "/images/gallery-1.jpg",
        "/images/gallery-2.jpg",
        "/images/gallery-3.jpg",
        "/images/gallery-4.jpg",
        "/images/gallery-5.jpg",
      ],
    };
  },
};
</script>

<style>
.customHeight {
  min-height: 500px;
}
.bgAdjust {
  background-position-x: 50%;
}

@media (min-width: 768px) {
  .bgAdjust {
    background-position-x: 40%;
  }
}
@media (min-width: 1280px) {
  .bgAdjust {
    background-position-x: 0%;
  }
}
</style>
